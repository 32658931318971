import React from "react"
import ReactAudioPlayer from 'react-audio-player';
import Layout from "../components/layout"
import { Container, Row, Col, Image, Card } from 'react-bootstrap'
import Header from "../img/header.jpg"
import Book from "../img/buch1.jpg"
import Audio from "../audio/FADA-hanisch.mp3"
import Fragebogen from "../downloads/fragebogen-grundbeduerfnisse.pdf"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/global.scss'

export default () => (
    <Layout>
        <Container className="position-relative d-md-flex justify-content-center align-items-center px-0 mb-5" fluid>
            <Image src={Header} fluid />
            <Container className="position-md-absolute text-center">
                <Row>
                    <Col xs="12" className="d-none d-md-block">
                        <h2 className="text-white mb-3">
                            Dr. Ernstfried Hanisch
            </h2>
                        <h1 className="text-white mb-0">
                            Mein Buch
            </h1>
                    </Col>
                    <Col xs="12" className="d-md-none mt-5">
                        <h2 className="mb-3">
                            Dr. Ernstfried Hanisch
            </h2>
                        <h1 className="mb-0">
                            Mein Buch
            </h1>
                    </Col>
                </Row>
            </Container>
        </Container>
        <Container>
            <Row className="mb-2">
                <Col xs="12">
                    <h2 className="mb-2">
                        In jeder Mücke steckt ein Elefant
                    </h2>
                    <h4 className="mb-4">
                        <i>Warum wir uns NICHT grundlos über Kleinigkeiten aufregen</i>
                    </h4>
                    <p className="mb-4">
                        Eine kritische Bemerkung, ein nicht erwiderter Gruß, nicht aufgeräumte Socken — unser Alltag bietet viele Gelegenheiten, sich über Kleinigkeiten aufzuregen. Aber sind es wirklich Kleinigkeiten? Grundlos reagiert niemand mit heftigen Gefühlen, daher lohnt es sich, die wahre Ursache für den großen Ärger ausfindig zu machen. Schnell zeigt sich: Wenn elementare seelische Bedürfnisse wie etwa das nach Wertschätzung, Autonomie und Sicherheit bedroht sind oder gar verletzt werden, wird am Fundament unseres Wohlbefindens gerüttelt. Alte Muster und überholte Bewältigungsversuche laufen dann gleichsam automatisch ab.
                        <br /><br />
                        Ich zeige anhand von einprägsamen Beispielgeschichten auf, wie wir diesen Prozess stoppen und unser inneres Gleichgewicht wiedererlangen können.
                        <br /><br />
                        Ein Selbsttest zum Ausloten der eigenen Bedürfnisse sowie zahlreiche Übungen helfen dabei, sich konstruktiv mit Verstimmungen auseinanderzusetzen und so aus Sackgassen herauszufinden.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={4} className="text-lg-left text-center">
                    <Image src={Book} fluid className="mw-2 mb-4" width="300px" height="auto" />
                </Col>
                <Col xs={12} lg={8} >
                    <p>
                        Ein Buch für alle, <br /><br />
                        <ul>
                            <li>die verstehen möchten, warum sie bzw. ihre Mitmenschen bei vermeintlich kleinen Anlässen so emotional reagieren</li>
                            <li>die herausfinden möchten, was ihnen im Leben wirklich wichtig ist</li>
                            <li>die ihre verschiedenen Bedürfnisse ausbalancieren möchten</li>
                            <li>die für eine Verhaltenstherapie, mit der sie alte Muster verändern wollen, ein gut verständliches Begleitbuch suchen.</li>
                        </ul>
                    </p>
                    <div className="text-lg-left text-center mb-4">
                        <a href="https://www.dtv.de/buch/ernstfried-hanisch-in-jeder-muecke-steckt-ein-elefant-34950/" target="_blank" className="btn btn-primary mt-5 mx-2">jetzt einkaufen</a>
                        <a href={Fragebogen} target="_blank" className="btn btn-secondary mt-5 mx-2">kostenloser Fragebogen</a>
                    </div>
                </Col>
            </Row>
            <Row className="mb-5 mt-4">
                <Col xs="12">
                    <h4 className="mb-4">
                        Sabine Waadt in Psychotherapie aktuell 1/10
                    </h4>
                    <p className="mb-3">
                        <i>
                            „Wer kennt das nicht: tagelanges Grübeln über einen an sich harmlosen Vorwurf, heftiger Zorn auf eine an sich kleine Ungerechtigkeit, bodenlose Scham bei einem an sich unbedeutendem Witz – lauter emotionale Elefanten, die aus kleinen Mückenstichen erwachsen sind.<br /><br />
                        In einer präzisen Sprache, anschaulich und mit Fallbeispielen, in denen wir uns leicht wieder erkennen, beschriebt Ernstfried Hanisch, wie sich unangemessene emotionale Reaktionen und Entladungen aus früheren belastenden Erfahrungen erklären können. Diese heute oft kaum noch erinnerten Verletzungen haben „wunde Punkte“ hinterlassen, die bei kleinsten Berührungen schmerzen können.<br /><br />
                        Wie wir daraus frustrierte Grundbedürfnisse erkennen, ernst nehmen und in ein geeignetes Verhältnis zu anderen Zielen in unserem Leben bringen können, erklärt Ernstfried Hanisch anhand eingängiger Beispiele, verständlicher Erklärungsmodelle aus der kognitiven Verhaltens- und Schematherapie. Er stellt einfache Fragekataloge und Tests vor, anhand derer wir Thema und Intensität solcher „Elefanten“ spezifizieren können. Er übersetzt gängige verhaltenstherapeutische Begriffe in einsichtige, bildhafte Erklärungsmodelle und Diagramme, um die aus unseren schmerzlichen Erfahrungen entstandenen Dilemmata zwischen widerstreitenden Bedürfnissen durch Analysemuster verständlich und bisherige Selbstschutzprogramme gegen die seelischen Schmerzen sichtbar zu machen. Schließlich hilft uns Ernstfried Hanisch in vielen Übungsanregungen und Hausaufgaben, diese grundlegenden Bedürfnisse zu erkennen und systematisch neue, sozial verträgliche Handlungsmöglichkeiten zu erarbeiten, mit dem Ziel, für sich selbst gut zu sorgen.<br /><br />
                        Ernstfried Hanisch hat ein sehr praxistaugliches Buch geschrieben, in dem nicht nur „jedermann“ sich in emotionalen Alltagskrisen und Selbstschutzprogrammen verstanden und angesprochen fühlen kann, sondern auch bewährte verhaltenstherapeutische Methoden zum Teil neu und überraschend zusammengestellt und in ihren Möglichkeiten vorgestellt werden. Ein Buch, das Therapeuten Patienten gut in die Hand geben und selbst noch etwas daraus lernen können."
                        </i>
                    </p>
                </Col>
            </Row>

            <Row className="mb-5">
                <Col xs={12}>
                    <h3 className="mb-4">
                        Information zum Buchkonzept für Kolleg*innen
                    </h3>
                    <p>
                        Ich habe ein Buch für Laien geschrieben und darin die Modelle der kognitiven Verhaltenstherapie und das Schemakonstrukt in eine leicht verständliche Begrifflichkeit  übersetzt und – im Unterschied zu Youngs Therapieansatz – auf alltägliche und nicht-pathologische Phänomene übertragen.
                        Neben der Erklärung von wunden Punkten habe ich den Schwerpunkt auf das Erkennen von Grundbedürfnissen und diesbezüglichen Konflikten gelegt. Die individuellen Muster im Umgang mit Bedürfnisverletzungen werden von mir als Selbstschutzprogramme gefasst, die häufig die Erreichung von Bedürfnisbefriedigung behindern.
                        Beides, Bedürfniskonflikte und dysfunktionale Bedürfnisäußerung bzw. Vermeidung von Bedürfnisverletzungen, lässt sich durch ein sog. Bedürfnisquadrat (Kap. 5) anschaulich machen, eine Darstellungsform, in der ich mich an das Wertequadrat von Hellwig und das Entwicklungsquadrat von Schulz von Thun angelehnt habe.
                        Nach der guten Resonanz der Erstausgabe 2009 habe ich diese überarbeitet und mit zusätzlichen Anleitungen erweitert. Meine Erfahrungen in der Therapie mit dem Fragebogen zu Grundbedürfnissen, der Bedürfnisbilanz und dem Bedürfnisquadrat sind insofern ermutigend, als einerseits Patienten geholfen wird, ihre Bedürfnisse zu erkennen und zu achten und andererseits ihnen vor Augen geführt werden kann, dass durch einseitige Orientierung an einzelnen Grundbedürfnissen andere vernachlässigt werden. Die Arbeit mit diesen Instrumenten erlaubt dabei eine sokratische Haltung des Therapeuten, da der Patient selbst herausfinden kann, was für ihn wichtig ist und welche für seine Entwicklung bedeutsamen Bedürfnisse er dabei vielleicht vernachlässigt. Dies dient dem Ziel einer größeren Ausgeglichenheit und größerer Autonomie. Außerdem hilft es im therapeutischen Prozess, die interaktionellen Spiele von Patienten in einen nachvollziehbaren Zusammenhang mit der Motivebene (im Sinne Sachses) zu bringen.
                        Die Rückmeldungen von KollegInnen und PatientInnen zeigen, dass das Buch als Arbeitsbuch für eine an Schemata orientierte Kognitive Verhaltenstherapie dienen kann.
                    </p>
                </Col>
            </Row>
            <Row className="mb-5">
                <Col xs="12">
                    <h4 className="mb-4">
                        Stimme zum Buch
                    </h4>
                    <p className="mb-3">
                        Ein Selbsthilfe– und Begleitbuch für eine schemaorientierte Verhaltenstherapie
                    </p>
                    <p className="mb-3">
                        „Gedacht ist dieses Buch als ein Selbsthilfebuch. Aus Sicht des Rezensenten ist es mehr: Natürlich können interessierte Leser mit Hilfe dieses Buches auf eine spannende Reise gehen, ihre Grundbedürfnisse und eventuelle Verletzungen erkennen und sich mit den hinter den Mücken versteckten Elefanten verbünden. Ausgesprochen hilfreich kann dieses Buch aber auch für Patienten sein, die sich in einer Psychotherapie befinden. Sie erhalten hier eine fundierte Hilfestellung und können begleitend zu ihrem Psychotherapieprozess besser verstehen, wie störende Verhaltensweisen bis hin zu hartnäckigen Symptomen letztlich aus frustrierten Grundbedürfnissen und fehlgeschlagenen Kompensationsversuchen resultieren. Fazit: Es ist sehr tröstlich zu wissen, dass man, um glücklich und zufrieden zu werden, sein Leben nicht immer neu erfinden muss, sondern dass es vielleicht auch ausreicht, die gewaltigen Elefanten hinter den Mücken zu entdecken, die uns das Leben schwer machen.“
                    </p>
                    <p className="mb-0 font-weight-bold">
                        Dr. Peter Kosarz, Psychotherapeutenjournal
                    </p>
                </Col>
            </Row>
            <Row id="team" className="mb-5">
                <Col xs="12">
                    <h3 className="mb-4">
                        Mein Interview
                    </h3>
                    <p className="mb-4">
                        Der Autor wurde vom Saarländischen Rundfunk im Rahmen einer traditionsreichen Sendereihe mit dem Titel „Fragen an den Autor – FADA“, jeweils sonntags von 11 – 12 Uhr, eingeladen. Das Gespräch findet jeweils zwischen dem Rundfunkredakteur, dem Autor und zugeschalteten Hörern, die Fragen zu dem jeweiligen Buchthema stellen können, statt. Das Live-Interview wurde anlässlich der Erstausgabe am 26.11.2009 gesendet. Hier können Sie mal rein hören, wenn Sie das Buchthema interessiert:
                    </p>
                    <Card className="text-center shadow">
                        <Card.Body>
                            <p>
                                Dr. Hanisch zu Gast beim Saarländischen Rundfunk zum Thema…
                            </p>
                            <ReactAudioPlayer
                                src={Audio}
                                controls
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </Layout>
)
